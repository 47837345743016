import React, {useState, useEffect, useRef} from "react";
import {Redirect} from 'react-router';
import DocumentTitle from 'react-document-title';
import 'babel-polyfill';

import 'bootstrap/dist/css/bootstrap.min.css';

import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "./App.css";

import { surveyLocalization } from "survey-core";

import api from './admin/services/Api';
import Error from './components/Error';
import Loader from './components/Loader';
import BackgroundedScreen from './components/BackgroundedScreen';
import Login from './components/Login';


import {StylesManager, Model} from "survey-core";
import {Survey} from "survey-react-ui";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "pretty-checkbox";
import "jquery-bar-rating/jquery.barrating.js";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import "jquery-bar-rating/dist/themes/bars-1to10.css";
import "jquery-bar-rating/dist/themes/bars-movie.css";
import "jquery-bar-rating/dist/themes/bars-pill.css";
import "jquery-bar-rating/dist/themes/bars-reversed.css";
import "jquery-bar-rating/dist/themes/bars-horizontal.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars-o.css";
import * as SurveyCore from "survey-core";
import {jquerybarrating} from "surveyjs-widgets";
import "survey-core/defaultV2.css";

jquerybarrating(SurveyCore);

export function App(props) {
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [needConnection, setNeedConnection] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [connectionError, setConnectionError] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("fr");
  const [post, setPost] = useState({});
  const [user, setUser] = useState(null);
  const [connected, setConnected] = useState(null);
  const [completed, setCompleted] = useState(null);
  const [checkingConnection, setCheckingConnection] = useState(null);
  const [authorizedForSurvey, setAuthorizedForSurvey] = useState(null);
  const [model, setModel] = useState(null);

  useEffect(() => {
    const loadSurveyConst = async () => {
      const apiSurvey = await loadSurvey();
      setLoading(false);
      setLoaded(!error);
      //Used only for hiding
      await prefillForm(apiSurvey)
    }
    if (typeof props.match === 'undefined' || typeof props.match.params === 'undefined' || typeof props.match.params.surveyslug === 'undefined') {
      setError('Survey does not exist');
      return;
    }
    if (!loaded && error === false) {
      loadSurveyConst()
    }

    const Popper = require("popper.js/dist/umd/popper.min");

    window['Popper'] = Popper;
    global['Popper'] = Popper;
    window["$"] = window["jQuery"] = $;
    global["$"] = global["jQuery"] = $;
    document.documentElement.lang = selectedLanguage;
  }, []);



  // var retryPreffill = false;

  const prefillForm = async (apiSurvey = null) => {
    // var params = getSearchParameters();
    // if(Object.keys(params).length)
    // {
    //   // setTimeout(() => {
    var params = getSearchParameters(apiSurvey);
    if (Object.keys(params).length) {
      Object.keys(params).forEach((k, i) => {
        if (!k) return true;
        // setTimeout(() => {
        var parentSelector = '[data-key=' + k + '0]';
        // if (!document.querySelector(parentSelector)) {
        //   // console.log('not ready ', k);
        //   if (!retryPreffill) {
        //     setTimeout(prefillForm, 500);
        //   }
        //   retryPreffill = true;
        // }
        // var selectorForRadio = parentSelector + ' input[value="' + params[k] + '"]';
        if (k.indexOf('hidden') > 0) {
          //In this case, we hide the block
          var elemToHide = parentSelector.replace('hidden', '');
          if (document.querySelector(elemToHide)) {
            document.querySelector(elemToHide).style.visibility = "hidden";
            document.querySelector(elemToHide).style.height = "0";
            document.querySelector(elemToHide).parentNode.style.minHeight = "0";
            document.querySelector(elemToHide).parentNode.style.margin = "0";
          }
        }
        // else if (document.querySelector(selectorForRadio)) {
        //   //In this case, we simulate a click on the wanted radio
        //   document.querySelector(selectorForRadio).click()
        // } else if (document.querySelector(parentSelector)) {
        //   var targetElem = 'a[data-rating-value="' + params[k] + '"]';
        //   if (document.querySelector(parentSelector) && document.querySelector(parentSelector).querySelector(targetElem)) {
        //     //In this case, we selected the wanted star
        //     document.querySelector(parentSelector).querySelector(targetElem).click();
        //   } else if(document.querySelector(parentSelector + ' input')) {
        //     //In this case, we set value and put the focus on to save answers
        //     document.querySelector(parentSelector + ' input').value = params[k];
        //     document.querySelector(parentSelector + ' input').focus();
        //   }
        // }
        // document.body.scrollTop = document.documentElement.scrollTop = 0;
        // }, i * 200);
      });
    }
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    // }
    // }, 1500);
    // }
  }

  const getSurveyElement = (key, json) => {
    for (const p in json.pages) {
      for (const q in json.pages[p].elements) {
        if (json.pages[p].elements[q].name === key) {
          return json.pages[p].elements[q];
        }
      }
    }
    return null;
  }

  const getSearchParameters = (fetchedSurvey = null) => {
    const paramsString = window.location.search.substr(1);
    const params = (new URLSearchParams(paramsString))
    //Parse int to int (bug barrating)
    // console.log(params.entries())
    var result = {};
    for (let [p, v] of params.entries()) {
      if (fetchedSurvey && typeof fetchedSurvey.json === 'object') {
        const parameter = getSurveyElement(p, fetchedSurvey.json);
        if (parameter && ['barrating', 'rating'].indexOf(parameter.type) !== -1) {
          result[p] = `${v}` === `${parseInt(v)}` ? parseInt(v) : v
        } else {
          result[p] = v
        }
      } else {
        result[p] = v
      }
      // console.log(p, v)
    }
    return result;
    // return Object.fromEntries(params.entries());
    // return paramsString !== null && paramsString !== "" ? transformToAssocArray(paramsString) : {};
  }

  const transformToAssocArray = (paramsString) => {
    var params = {};
    var paramsArray = paramsString.split("&");
    for (var i = 0; i < paramsArray.length; i++) {
      var tempArray = paramsArray[i].split("=");
      params[tempArray[0]] = decodeURI(tempArray[1]);
    }
    return params;
  }

  const initModel = (loadedSurvey = null, locale = null) => {
    const json = (loadedSurvey || survey).json;

    for (const p in json.pages) {
      for (const q in json.pages[p].elements) {
        if (["radiogroup", "checkbox", "boolean", "matrix"].indexOf(json.pages[p].elements[q].type) > -1) {
          json.pages[p].elements[q].renderAs = 'prettycheckbox';
        }

        if (json.pages[p].elements[q].choicesOrder === 'random') {
          json.pages[p].elements[q].choices = json.pages[p].elements[q].choices.sort(() => Math.random() - 0.5);
        }
      }
    }

    surveyLocalization.defaultLocale = 'fr';

    const newModel = new Model({...json});
    newModel.locale = locale || selectedLanguage;
    // newModel.defaultLocale
    // console.log('languages', languages);
    // surveyLocalization.defaultLocale = "ro";
    newModel.currentPageNo = currentPage;
    newModel.data = answers;
    setModel(newModel);
  }

  const loadSurvey = async () => {
    setLoading(true);
    setError(false);
    var params = getSearchParameters(null);
    try {
      const apiPost = await api.getSurvey(props.match.params.surveyslug, params['prid']);
      const {survey: apiSurvey, answers, currentPage = 0} = apiPost;
      if (apiSurvey && apiSurvey.customFields && !apiSurvey.customFields.enabled) {
        setError(apiSurvey.customFields.surveyDisabledError);
        setLoading(false);
        setLoaded(false)
      }
      params = getSearchParameters(apiSurvey);
      setSurvey(apiSurvey);
      await initModel(apiSurvey);
      setAnswers({...answers, ...params});
      setCurrentPage(currentPage);
      setError(false);
      // setLoaded(true);
      setPost(apiPost);
      return apiSurvey;

    } catch (e) {
      let customFields = {};
      if (e.response && e.response.customFields) {
        customFields = e.response.customFields;
      }
      if (e.statusCode === 403) {
        if (!user) {
          setError(false);
          // setLoaded(false);
          setNeedConnection(true);
          setConnected(false);
          setSurvey({customFields})
          await checkConnection();
        } else {
          setError(customFields && customFields.notAuthorizedSurvey ? customFields.notAuthorizedSurvey : 'You are not allowed to realize this survey');
          // setLoaded(false);
          setNeedConnection(true);
          setConnected(false);
          setSurvey({customFields})
        }
        return;
      }
      if (e.statusCode === 401) {
        setError(customFields && customFields.surveyAlreadyDone ? customFields.surveyAlreadyDone : "You've already done this survey");
        // setLoaded(false);
        setNeedConnection(true);
        setConnected(true);
        setSurvey({customFields})
        return;
      }
      setError(e.response && typeof e.response === 'object' && e.response.message ? e.response.message : e.message);
      setErrorTitle('Ouuppss !');
      return;
    }
    ;
  }

  useEffect(() => {
    if (survey && survey.json) {
      prefillForm(survey);
      }
  }, [survey, model])

  const fixAnswerTypes = (answersData) => {
    const result = {};
    for(var k of Object.keys(answersData)) {
      const parameter = getSurveyElement(k, survey.json);
      if (parameter && ['barrating', 'rating'].indexOf(parameter.type) !== -1) {
        result[k] = `${answersData[k]}` === `${parseInt(answersData[k])}` ? parseInt(answersData[k]) : answersData[k]
      } else {
        result[k] = answersData[k]
      }
    }
    return result;
  }

  const onComplete = async (result, options) => {
    options.showDataSaving();
    try {
      const postApi = await api.saveSurveyPost(post._id, {
        answers: result.data,
      }, true);
      setAnswers(fixAnswerTypes({
        ...answers,
        ...result.data
      }));
      setPost(postApi);
      setCompleted(true);
      options.showDataSavingSuccess();
    } catch (e) {
      if (e.statusCode && e.statusCode === 403) {
        setNeedConnection(true);
        setLoaded(false);
        setConnected(false);
        setConnectionError(survey && survey.customFields ? survey.customFields.sessionError : null);
      } else {
        throw new Error({
          ...e,
          answers: {
            ...answers,
            ...result.data
          },
          post: post ? post : null,
          completed: true
        });
      }
      options.showDataSavingError();
    }
  }

  const savePartial = async (result) => {
    try {
      await api.saveSurveyPost(post._id, {
        answers: fixAnswerTypes(result.data),
        currentPage: result.currentPageNo + 1
      });
    } catch (e) {
      if (e.statusCode && e.statusCode === 403) {
        setNeedConnection(true);
        setLoaded(false);
        setConnected(false);
        setConnectionError(survey && survey.customFields ? survey.customFields.sessionError : null);
      } else {
        throw new Error({
          ...e,
          answers: {
            ...answers,
            ...result.data
          },
          post: post ? post : null,
          completed: false,
          currentPage: result.currentPageNo + 1
        });
      }
    }
  }

  const getLoadingBody = (text = false) => {
    let customFields = {};
    return (
      <Loader text={text}
              backgroundImage={survey ? survey.customFields.backgroundImage : (customFields ? customFields.backgroundImage : null)}/>
    );
  }

  const onLogin = (user) => {
    let authorizedForSurvey = true;
    setConnected(true);
    setUser(user);
    setAuthorizedForSurvey(authorizedForSurvey);
    setLoading(true);
    loadSurvey();
  };

  const notAuthorizedBody = () => {
    return (
      <div>
        {survey.customFields.notAuthorizedSurvey}
      </div>
    );
  };

  const renderToastedScreen = (content, title) => {
    return (
      <BackgroundedScreen
        backgroundImage={survey.customFields.backgroundImage ? survey.customFields.backgroundImage : null}
        title={title}>
        {content}
      </BackgroundedScreen>
    );
  }

  const renderLogin = () => {
    if (checkingConnection) {
      return <Loader/>;
    } else if (connected) {
      return renderToastedScreen(notAuthorizedBody(), (
        <div className={"text-center"}>
          {survey.customFields.loginLogo ?
            <div className={"loginLogo"} style={{padding: 20}}><img alt="Logo" src={survey.customFields.loginLogo}/>
            </div> : null}
          {survey.customFields.loginTitle ?
            <div className={"loginTitle"} style={{padding: 20}}>{survey.customFields.loginTitle}</div> : null}
          {survey.customFields.loginText ? <div className={"loginText text-secondary font-weight-light"}
                                                style={{padding: 20}}>{survey.customFields.loginText.split('\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
          })}</div> : null}
        </div>
      ));
    } else {
      return <Login customFields={survey.customFields} onLogin={onLogin} connectionError={connectionError}/>;
    }
  }

  const checkConnection = async () => {
    setCheckingConnection(true);
    api.getLoggedInUser()
      .then((user) => {
        let authorizedForSurvey = false;
        if (user.authorizedSurveys.indexOf(survey._id) > -1) {
          authorizedForSurvey = true;
        }
        setCheckingConnection(false);
        setConnected(true);
        setAuthorizedForSurvey(authorizedForSurvey);
        setUser(user);
      })
      .catch((e) => {
        setCheckingConnection(false);
      })
    ;
  }

  if (survey && survey.customFields && survey.customFields.customCss) {
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = '/custom-css/' + survey.customFields.customCss;
    document.querySelector('head').append(link);
  }

  if (completed) {
    if (survey.customFields.quiz) {
      return (<Redirect to={`/survey/result/${post._id.toString()}`}/>);
    } else if (typeof survey.customFields.doNotRedirectToCompletePage === 'undefined' || !survey.customFields.doNotRedirectToCompletePage) {
      return (<Redirect to={`/survey/thankyou/${post._id.toString()}`}/>);
    }
  }

  if (error) {
    return <Error error={error} title={errorTitle ? errorTitle : null}/>;
  }

  if (loading) {
    return getLoadingBody();
  }

  // useEffect(() => {
  //   console.log('effect survey.json')
  //   // initModel();
  // }, [survey.json]);

  if (model) {
    if (completed && !model.isCompleted) {
      model.doComplete();
    }
    model.onComplete.add(onComplete);
    model.onPartialSend.add(savePartial);
    StylesManager.applyTheme("defaultV2");

    var customCss = {
      rating: {
        root: "custom-rating"
      },
      footer: "custom-footer",
      navigationButton: "custom-nav btn btn-primary"
    };

    const changeActiveLanguage = async (lang) => {
      document.documentElement.lang = lang;
      setSelectedLanguage(lang);
      initModel(survey, lang);
    }

    const languages = model.getUsedLocales();
    if (languages.indexOf('fr') === -1) {
      languages.push('fr');
    }

    return (
      <>
        <style>
          {
            `
          body {
            --background: ${survey.style["body-background-color"]};
            --background-dim: ${survey.style["body-container-background-color"]};
            --red-light: ${survey.style["error-background-color"]};
            --red: ${survey.style["error-color"]};
            --background-dim-light: ${survey.style["inputs-background-color"]};
            --primary: ${survey.style["main-color"]};
            --primary-light: ${survey.style["main-hover-color"]};
            --foreground: ${survey.style["text-color"]};
            --foreground-light: ${survey.style["header-color"]};
          }
          .sd-btn--action:not(:disabled):hover, .sd-btn--action:not(:disabled):focus {
            background-color: var(--background-dim);
          }
          .sd-root-modern:not(.svc-tab-designer) .sd-container-modern__title {
            background-color: ${survey.style["header-background-color"]};
          }
          `
          }
        </style>

        <DocumentTitle title={survey.name}>
          <BackgroundedScreen large
                              backgroundImage={survey.customFields.backgroundImage ? survey.customFields.backgroundImage : null}
                              customCss={customCss} style={survey.style ? survey.style : null}>
            {survey.customFields.headerImage ? (
              <div style={{marginBottom: 20, textAlign: 'center'}}>
                <img alt="Header" src={survey.customFields.headerImage} style={{maxWidth: '100%'}}/>
              </div>
            ) : null}
            <div className="surveyjs">
              {languages && languages.length > 1 ? (
              <select
                style={{position: 'absolute', right: 10, top: 10, padding: "8px 10px", borderRadius: 10, background: 'white', border: '0', textTransform: 'uppercase'}}
                onChange={(e) => changeActiveLanguage(e.target.value)}
                value={model.getLocale()}
              >
                {languages.map((language, index) =>
                  <option key={index} value={language}>{language}</option>
                )}
              </select>
                ) : null}
              {/* <select onChange={(e) => changeActiveLanguage(e.target.value)}>
    <option value="en" selected="selected">EN</option>
    <option value="fr">FR</option>
 </select> */}
              <Survey
                model={model}
                data={answers}
                // currentPage={currentPage}
                // onComplete={onComplete}
                // onPartialSend={savePartial}
              />
            </div>
          </BackgroundedScreen>
        </DocumentTitle>
      </>
    );
  }
  if (needConnection && (!connected || !authorizedForSurvey)) {
    return renderLogin();
  }
  return null;
}
