import React, {useEffect, useState} from "react";
import { SurveyCreator } from "survey-creator-react";
// import * as SurveyKo from "survey-knockout";
import $ from "jquery";
import "jquery-bar-rating/jquery.barrating.js";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import "jquery-bar-rating/dist/themes/css-stars.css";

import "survey-creator-core/survey-creator-core.i18n.js";
import { surveyLocalization } from "survey-core";
import "survey-core/survey.i18n";
import { localization, editorLocalization } from "survey-creator-core";
import api from './admin/services/Api';
import { Button, Form, FormGroup, Input, Row, Col, Label } from 'reactstrap';
import slugify from 'slugify';
import CustomizeSurveyCard from './components/CustomizeSurveyCard';
import Loader from './components/Loader';
import Error from './components/Error';
import Logo from './logo.svg';

import * as SurveyCore from "survey-core";
import * as widgets from "surveyjs-widgets";


import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.min.css";

let currentSurvey = null;

window["$"] = window["jQuery"] = $;
widgets.jquerybarrating(SurveyCore, $);

localization.currentLocale = "fr";
SurveyCore.surveyLocalization.defaultLocale = "fr";
editorLocalization.currentLocale = "fr";

export function SurveyCreatorWidget(props) {
  const saveButtonCustomFiels = document.getElementById('buttonSaveCustomFields');
  const creatorOptions = {
    isAutoSave: false,
    showState: true,
    useTabsInElementEditor: true,
    showJSONEditorTab: false,
    showTestSurveyTab: false,
    showEmbededSurveyTab: false,
    showTranslationTab: true
  };
  const [creator] = useState(new SurveyCreator(creatorOptions));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isCustomizeVisible, setIsCustomizeVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [survey, setSurvey] = useState({
    style: {
      'body-background-color': 'white',
      'body-container-background-color': '#f4f4f4',
      'border-color': '#e7e7e7',
      'error-background-color': '#fd6575',
      'error-color': '#ed5565',
      'header-background-color': '#e7e7e7',
      'header-color': '#6d7072',
      'inputs-background-color': '#f9f9f9',
      'main-color': '#1ab394',
      'main-hover-color': '#0aa384',
      'text-color': '#6d7072',
    },
    customFields: {
      points: 100,
      quiz: false,
      onceperuser: false,
      secured: false,
      enabled: true,
      loginTitle: 'Connexion',
      resultTitle: 'Votre résultat',
      levels: [],
      reportName: '',
      categoryRequired: false,
      loginText: '',
      loginButton: 'Sign in',
      resultText: '',
      resultScoreTop: 0,
      resultScoreLeft: 0,
      resultLevelTop: 0,
      resultLevelLeft: 0,
      loginLogo: '',
      headerImage: '',
      levelImage: '',
      backgroundImage: '',
      compareToSurvey: '',
      comparedToSurveyStable: 'You had %pointsDiff% compared to %oldSurveyName% !',
      comparedToSurveyPositive: 'You had %pointsDiff% compared to %oldSurveyName% !',
      comparedToSurveyNegative: 'You had %pointsDiff% compared to %oldSurveyName% !',
      notAuthorizedSurvey: 'You are not authorized to realize this survey',
      surveyAlreadyDone: "You've already done this survey",
      sessionError: 'Your session expired, please sign in again to continue',
      surveyDisabledError: 'This survey is not available anymore',
      objectDataVenueField: '', //Venue matching field in object data
      objectDataVenueFieldMapping: '', //Venue field to use to compare with object data venue field
      doNotRedirectToCompletePage: false
    },
    customCss: null
  })


  
  const loadSurvey = () => {
    api.getAdminSurvey(props.match.params.surveyid)
    .then((surveyApi) => {
      const getSurvey = {
        ...surveyApi,
        customFields: {
          ...survey.customFields,
          ...surveyApi.customFields
        },
        customCss: surveyApi.customCss,
        style: {
          ...survey.style,
          ...surveyApi.style
        }
      };
      setSurvey(getSurvey);
      setLoading(false);
      setError(false);
      console.log(SurveyCore.Serializer)
      // SurveyCore.Serializer.addProperty("question", {
      //   name: "category",
      //   choices: () => typeof survey.customFields != 'undefined' && survey.customFields.questionCategories ?
      //     ['none', ...survey.customFields.questionCategories] :
      //     ['none']
      // });
      SurveyCore.Serializer.addProperties("question", [
        {
          category: 'Stat configuration',
          name: "order",
          displayName: 'Order in statistics page (1, 2... no order = not visible)',
          type: 'number',
        },
        {
          category: 'Stat configuration',
          name: "graphType",
          choices: [
            {text: 'None', value: ''},
            {text: 'bar', value: 'bar'},
            {text: 'doughnut', value: 'doughnut'},
            {text: 'pie', value: 'pie'}
          ]
        },
        {
          category: 'Stat configuration',
          name: 'customResults',
          displayName: 'Custom Results',
          type: 'text',
          onSetValue: function (surveyElement, value) {
            // You can perform required checks or modify the `value` here
            // ...
            // Set the `value`
            // surveyElement.errors
            surveyElement.setPropertyValue("customResults", value);
            // You can perform required actions after the `value` is set
            // ...
          }
        }
      ])
      creator.saveSurveyFunc = saveMySurvey;
      creator.showErrorOnFailedSave = true;
      creator.text = JSON.stringify(getSurvey.json);
      creator.locale = "fr";
      creator.currentLocale = "fr";
      creator.render("creatorElement");
      creator
      .toolbox
      .addItem({
        name: "rating_custom",
        isCopied: true,
        iconName: "icon-default",
        title: "ratings custom",
        json: {
          "type": "rating",
          optionsCaption: "Select a country...",
          choicesByUrl: {
            url: "https://surveyjs.io/api/CountriesExample"
          }
        }
      });
    })
    .catch((e) => {
      console.log(e);
      setError(e.statusCode === 403 ? 'Please login to UpMyShop Dashboard' : true);
      setLoading(false);
    });
  }

  const toggleCustomizeCard = () => {
    setIsCustomizeVisible(!isCustomizeVisible);
  }

  const handleChange = (name, value, datatree = 'customFields') => {
    const newState = {};
    newState[datatree] = {
      ...survey[datatree],
      [name]: value
    };
    setSurvey({
      ...survey,
      ...newState
    })
    saveButtonCustomFiels.style.display = 'block'
  };


  const saveCustomFields = () => {
    setSaving(true);
    const newSurvey = {
      ...survey
    };
    delete newSurvey._id;
    api.saveSurvey(survey._id, {
      ...newSurvey
    }).then((res) => {
      setSaving(false);
      if (res && res.success) {
        saveButtonCustomFiels.style.display = 'none';
      } else {
        setError("Not save");
      }
    }).catch((e) => {
      setSaving(false);
      setError("Not save");
    });
  }

  const reload = () => {
    setError(false);
    setLoading(true);
    loadSurvey();
  }

  const getLoadingBody = () => {
    return (<Loader />);
  }

  const getErrorBody = () => {
    return (
      <Error error={error} retryCallback={reload} />
    );
  }

  const saveMySurvey = (saveNo, callback) => {
    setSaving(true);
    const newSurvey = {
      ...currentSurvey,
      json: JSON.parse(creator.text)
    };
    delete newSurvey._id;

    api.saveSurvey(currentSurvey._id, {
      ...newSurvey
    }).then((res) => {
      setSaving(false);
      if (res && res.success) {
        callback(saveNo, true);
      } else {
        callback(saveNo, false);
      }
    }).catch((e) => {
      setSaving(false);
      callback(saveNo, false);
    });
    setSurvey({
      ...currentSurvey,
      ...newSurvey
    });   
  };

  useEffect(() => {
    if (typeof props.match.params.surveyid === 'undefined') {
      setLoading(false);
      setError('Survey id not found');
    }
    loadSurvey();
  }, []);

  useEffect(() => {
    currentSurvey = survey;
  }, [survey]);

  if (error) {
    console.log(error);
    return getErrorBody();
  }

  if (loading) {
    return getLoadingBody();
  } else {
    return (
      <>
        <header className="text-center">
          <div className="surveyCreatorLogo"><img src={Logo} alt="UpMyShop" /></div>
        </header>
        <div className="container-fluid surveyCreatorContainer">
          <Form>
            <div className="surveyCreatorCustomForm">
              <Row className="align-items-end">
                <Col lg="4">
                  <FormGroup>
                    <Label for="surveyName">Survey name</Label>
                    <Input type="text" name="name" id="surveyName" placeholder="Survey name" value={survey.name || ''} onChange={(evt) => {setSurvey({...survey, name: evt.target.value, slug: slugify(evt.target.value.toLowerCase())})}} />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label for="surveySlug">Uri</Label>
                    <Input type="text" name="slug" id="surveySlug" placeholder="Uri" value={survey.slug || ''} onChange={(evt) => {setSurvey({...survey, slug: evt.target.value})}} />
                  </FormGroup>
                </Col>
                <Col lg="3" className="surveyCreatorCustomFormActions">
                  <Button className="w-100" color="secondary" size="md" onClick={toggleCustomizeCard}>{isCustomizeVisible ? 'Hide' : 'Show'} Custom Fields</Button>
                  <Button style={{display: 'none'}} id="buttonSaveCustomFields" className="w-100 mt-1" color="primary" size="md" onClick={saveCustomFields}>Save Custom Fields</Button>
                </Col>
              </Row>
            </div>
            <CustomizeSurveyCard isOpen={isCustomizeVisible} onChange={handleChange} styleFields={survey.style} otherFields={survey.customFields} survey={survey} />
          </Form>
        </div>
        {saving ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red',     position: 'fixed',
            zIndex: 1000,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.5)',
            top: 0}}>
            <div className={"lds-ripple"}>
              <div></div>
              <div></div>
            </div>
            Saving survey, please wait...</div>
          : null}
          <div id="creatorElement"></div>
        </>
          )
        }
        }
